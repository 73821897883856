import React, { lazy, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Flex } from "@chakra-ui/react";

const Header = lazy(() => import("./Header/Header"));
const MenuBar = lazy(() => import("./MenuBar/MenuBar"));
const Layout: React.FC = () => {
    const scrollablePaths = [
        "/setting/config/edit/1",
        "/",
        "/setting/menus",
        "/dataset",
        "/datalake",
        "/setting/datalake/create",
        "/setting/database/create",
        "/setting/menus/create",
        "/setting/roles/create",
        "/setting/permissions/create",
        "/setting/users",
        "/setting/users/create"
    ];
    const menusEditPattern = /^\/setting\/menus\/edit\/\d+$/;
    const usersEditPattern = /^\/setting\/users\/edit\/\d+$/;
    const rolesEditPattern = /^\/setting\/roles\/edit\/\d+$/;
    const permissionsEditPattern = /^\/setting\/permissions\/edit\/\d+$/;
    const { pathname } = useLocation();

    const isScrollable = scrollablePaths.includes(pathname) || menusEditPattern.test(pathname) || usersEditPattern.test(pathname) || rolesEditPattern.test(pathname) || permissionsEditPattern.test(pathname);

    return (
        <Flex direction="column" h="100vh" 
        overflow={isScrollable ? "scroll" : "hidden"}
        bgColor="#EDEFF4">
            <Suspense fallback={<div>Loading...</div>}>
                <Header />
            </Suspense>

            <Flex p="4" px="9" pb="28" flexDirection="column">
                {/* <Flex alignItems="center" justifyContent="flex-end" mt="4">
          <Breadcrumb />
        </Flex> */}
                <Outlet />
            </Flex>

            <Suspense fallback={<div>Loading...</div>}>
                <MenuBar />
            </Suspense>
        </Flex>
    );
};

export default Layout;
